/*
 * brand-overrides.less - Custom brand styles that override procentral ones
 */

td[data-title="Favorite: "] {
  filter: brightness(0%) invert(10%) sepia(63%) saturate(5256%) hue-rotate(288deg) brightness(100%) contrast(106%);
}

.login .modal {
  background-image: url(/images/procentral-logo-light.svg);
}
